import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaRegCircleUser } from "react-icons/fa6";
import { Link, Outlet, useNavigate } from "react-router-dom";
import ROLE from "../common/role";
import { setUserDetails } from "../store/userSlice"; // Adjust path as needed
import SummaryApi from "../common";
import { toast } from "react-toastify"; // Import toast for notifications

const AdminPanel = () => {
  const [localLoading, setLocalLoading] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const user = useSelector((state) => state?.user?.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        setLocalLoading(true);
        const dataResponse = await fetch(SummaryApi.current_user.url, {
          method: SummaryApi.current_user.method,
          credentials: "include",
        });

        const dataApi = await dataResponse.json();

        if (dataApi.success) {
          dispatch(setUserDetails(dataApi.data));
        }
      } catch (error) {
        toast.error("Failed to fetch user details:");
       
      } finally {
        setLocalLoading(false);
      }
    };

    fetchUserDetails();
  }, [dispatch]);

  useEffect(() => {
    if (!localLoading && user?.role !== ROLE.ADMIN) {
      navigate("/");
    }
  }, [user, localLoading, navigate]);

  const handleLogout = async () => {
    try {
      const fetchData = await fetch(SummaryApi.logout_user.url, {
        method: SummaryApi.logout_user.method,
        credentials: "include",
      });

      const data = await fetchData.json();

      if (data.success) {
        toast.success(data.message);
        dispatch(setUserDetails(null));
        navigate("/");
      }

      if (data.error) {
        toast.error(data.message);
      }
    } catch (error) {
      // console.error("Logout failed:", error);
      toast.error("Logout failed. Please try again.");
    }
  };

  if (localLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-[auto,1fr] min-h-screen">
      {/* Mobile Menu Button */}
      <button
        onClick={() => setSidebarOpen(!sidebarOpen)}
        className="md:hidden p-4 text-gray-600 hover:text-gray-900 transition duration-300"
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          ></path>
        </svg>
      </button>

      {/* Sidebar */}
      <aside
        className={`bg-white w-full md:w-64 lg:w-80 p-4 shadow-md md:block ${
          sidebarOpen ? "block" : "hidden"
        }`}
      >
        <div className="flex flex-col items-center mb-6">
          <div className="relative mb-4">
            {user?.profilePic ? (
              <img
                src={user?.profilePic}
                className="w-24 h-24 rounded-full border-4 border-gray-200"
                alt={user?.name}
              />
            ) : (
              <FaRegCircleUser className="text-6xl text-gray-400" />
            )}
          </div>
          <p className="text-xl font-semibold">{user?.name}</p>
          <p className="text-sm text-gray-600">{user?.role}</p>
        </div>

        <nav className="space-y-2">
          <Link
            to="all-users"
            className="block px-4 py-2 rounded-lg hover:bg-gray-200 transition duration-300"
          >
            All Users
          </Link>
          <Link
            to="all-enquiries"
            className="block px-4 py-2 rounded-lg hover:bg-gray-200 transition duration-300"
          >
           All Enquiries
          </Link>
          <Link
            to="all-category"
            className="block px-4 py-2 rounded-lg hover:bg-gray-200 transition duration-300"
          >
            All Categories
          </Link>
          <Link
            to="all-products"
            className="block px-4 py-2 rounded-lg hover:bg-gray-200 transition duration-300"
          >
            All Products
          </Link>
          <Link
            to="logo-update"
            className="block px-4 py-2 rounded-lg hover:bg-gray-200 transition duration-300"
          >
            Update Logo
          </Link>
          <Link
            to="banner-update"
            className="block px-4 py-2 rounded-lg hover:bg-gray-200 transition duration-300"
          >
            Update Banner
          </Link>
          <Link
            to="gallery-update"
            className="block px-4 py-2 rounded-lg hover:bg-gray-200 transition duration-300"
          >
            Update Gallery
          </Link>
          <Link
            to="create-blog"
            className="block px-4 py-2 rounded-lg hover:bg-gray-200 transition duration-300"
          >
            Create Blog
          </Link>
          <Link
            to="about-us"
            className="block px-4 py-2 rounded-lg hover:bg-gray-200 transition duration-300"
          >
            Update About
          </Link>
          
          <Link
            to="contact-in"
            className="block px-4 py-2 rounded-lg hover:bg-gray-200 transition duration-300"
          >
            Google Map / Address / Contact
          </Link>
          <Link
            to="email-in"
            className="block px-4 py-2 rounded-lg hover:bg-gray-200 transition duration-300"
          >
            Email Integration
          </Link>
          <Link
            to="settings"
            className="block px-4 py-2 rounded-lg hover:bg-gray-200 transition duration-300"
          >
             Whatsapp/Call integration
          </Link>
          <Link
            to="FAQ"
            className="block px-4 py-2 rounded-lg hover:bg-gray-200 transition duration-300"
          >
             Faq 
          </Link>
          <Link
            to="PRIVACY"
            className="block px-4 py-2 rounded-lg hover:bg-gray-200 transition duration-300"
          >
             Update Privacy & Policy
          </Link>
          <Link
            to="permis"
            className="block px-4 py-2 rounded-lg hover:bg-gray-200 transition duration-300"
          >
             Permission-other Pages
          </Link>
        </nav>

        {/* Logout Button */}
        <button
          onClick={handleLogout}
          className="w-full mt-4 px-4 py-2 text-white bg-red-600 hover:bg-red-700 rounded-lg transition duration-300"
        >
          Logout
        </button>
      </aside>

      {/* Main content */}
      <main
        className={`flex-1 p-6 bg-gray-100 transition-all duration-300 ${
          sidebarOpen ? "md:ml-64" : "md:ml-0"
        }`}
      >
        <Outlet />
      </main>
    </div>
  );
};

export default AdminPanel;
