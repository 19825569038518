import React, { useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';

const WhatsappAndCallIntegration = () => {
  const [settings, setSettings] = useState({
    showWhatsAppIcon: true,
    showCallIcon: true,
    whatsappNumber: '',
    callNumber: '',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch the current settings
    const fetchSettings = async () => {
      try {
        const response = await fetch('https://boostapi.biztoindia.in/api/get-setting');
        if (!response.ok) {
          throw new Error('Failed to fetch');
        }
        const data = await response.json();
        setSettings(data);
      } catch (error) {
        toast.error('Failed to fetch settings');
      }
    };

    fetchSettings();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch('https://boostapi.biztoindia.in/api/update-setting', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(settings)
      });

      if (response.ok) {
        toast.success('Settings updated successfully');
        // Reset the form fields
        setSettings({
          showWhatsAppIcon: true,
          showCallIcon: true,
          whatsappNumber: '',
          callNumber: '',
        });
      } else {
        toast.error('Failed to update settings');
      }
    } catch (error) {
      toast.error('An error occurred while updating settings');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6 max-w-lg mx-auto bg-white rounded-lg shadow-md">
      <Toaster />
      <h1 className="text-2xl font-bold mb-4">WhatsApp and Call Integration</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex items-center">
          <input
            type="checkbox"
            name="showWhatsAppIcon"
            checked={settings?.showWhatsAppIcon}
            onChange={handleChange}
            className="mr-2"
          />
          <label htmlFor="showWhatsAppIcon" className="text-lg">Show WhatsApp Icon</label>
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            name="showCallIcon"
            checked={settings?.showCallIcon}
            onChange={handleChange}
            className="mr-2"
          />
          <label htmlFor="showCallIcon" className="text-lg">Show Call Icon</label>
        </div>
        <div>
          <label htmlFor="whatsappNumber" className="block text-sm font-medium mb-1">WhatsApp Number</label>
          <input
            type="text"
            id="whatsappNumber"
            name="whatsappNumber"
            value={settings?.whatsappNumber || ''}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label htmlFor="callNumber" className="block text-sm font-medium mb-1">Call Number</label>
          <input
            type="text"
            id="callNumber"
            name="callNumber"
            value={settings?.callNumber || ''}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
          />
        </div>
        <button
          type="submit"
          disabled={loading}
          className="w-full py-2 px-4 bg-blue-500 text-white font-bold rounded-md hover:bg-blue-600 disabled:bg-gray-400"
        >
          {loading ? 'Updating...' : 'Update Settings'}
        </button>
      </form>
    </div>
  );
};

export default WhatsappAndCallIntegration;
