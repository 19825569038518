import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';
import FloatingIcons from './FloatingIcons'; // Import the FloatingIcons component
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import SummaryApi from '../common';

const Footer = () => {
  const [aboutData, setAboutData] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [showFullAbout, setShowFullAbout] = useState(false);
  const navigate = useNavigate();

 
  const [allProduct,setAllProduct] = useState([])

  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const response = await fetch('https://boostapi.biztoindia.in/api/aboutget'); // Replace with your actual API route
        const data = await response.json();
        setAboutData(data);
      } catch (error) {
        console.error('Error fetching About Us data:', error);
      }
    };

    const fetchPermissions = async () => {
      try {
        const response = await fetch('https://boostapi.biztoindia.in/api/permission-get'); // API route for permissions
        const data = await response.json();
        // console.log("dattatfromresponse",data.permissions[0])
        setPermissions(data.permissions[0]);
      } catch (error) {
        console.error('Error fetching permissions:', error);
      }
    };
   
    const fetchAllProduct = async() =>{
      const response = await fetch(SummaryApi.allProduct.url)
      const dataResponse = await response.json()
  
      // console.log("product data",dataResponse)
  
      setAllProduct(dataResponse?.data || [])
    }
  
    


    fetchAboutData();
    fetchPermissions();
    fetchAllProduct();
  }, []);

  const handleReadMore = () => {
    navigate('/about'); // Navigate to the /about-us page
  };

  const getShortenedText = (text, length = 100) => {
    if (text?.length > length) {
      return text.substring(0, length) + '...';
    }
    return text;
  };

  return (
    <>
      <footer className="bg-red-700 text-white py-10">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-1 text-sm">
            {/* Company Info */}
            <div>
              <h2 className="text-lg font-semibold mb-4">{aboutData?.companyName || 'Company Name'}</h2>
              <p>{getShortenedText(aboutData?.aboutUs, 150)}</p>
              <button
                onClick={handleReadMore}
                className="mt-4 flex items-center px-4 py-2 bg-white text-black rounded hover:bg-gray-200 transition-colors"
              >
                Read More <FiArrowRight className="ml-2" />
              </button>
            </div>
            <div>
              <h2 className="text-lg font-semibold mb-4 gap-1">Products</h2>
              <ul>
                  
               
               {
                 allProduct?.map((pname, index)=>(
                  <Link to={'/product/' + pname?._id}>
                   <li key={index}>
  {pname?.productName.length > 20
    ? pname.productName.substring(0, 20) + '...'
    : pname.productName}
</li>
</Link>
                 ))
               }




                
              </ul>
            </div>

                 


            {/* Quick Links */}
            <div>
              <h2 className="text-lg font-semibold mb-4">Quick Links</h2>
              <ul>
                <li className="mb-2" key={1}>
                  <a href="/" className="hover:underline">Home</a>
                </li>
                <li className="mb-2" key={2}>
                  <a href="/all-products" className="hover:underline">Products</a>
                </li>
                <li className="mb-2" key={3}>
                  <a href="/about" className="hover:underline">About Us</a>
                </li>
                <li className="mb-2" key={4}>
                  <a href="/contact" className="hover:underline">Contact Us</a>
                </li>
              </ul>
            </div>

            {/* Customer Service */}
            <div>
              <h2 className="text-lg font-semibold mb-4">Customer Service</h2>
              <ul>
                {permissions?.showFaq && (
                  <li className="mb-2" key={permissions?._id}>
                  <a href="/faq" className="hover:underline">FAQ</a>
                </li>
                ) }
                
                {permissions?.showShippingPage&&(
                  <li className="mb-2" key={permissions?.showShippingPage+1}>
                  <a href="/shipping" className="hover:underline">Shipping Info</a>
                </li>
                )
              }
                {permissions?.showPrivacy && (
 <li className="mb-2" key={permissions?._id}>
 <a href="/privacy-policy" className="hover:underline">Show Privacy</a>
</li>
                )}
               
              </ul>
            </div>

            {/* Newsletter and Social Media */}
            <div>
              <h2 className="text-lg font-semibold mb-4">Stay Connected</h2>
              <form className="flex flex-col sm:flex-row items-start">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="p-2 mb-2 sm:mb-0 sm:mr-2 rounded bg-white text-black focus:outline-none focus:ring focus:ring-gray-500"
                />
                <button
                  type="submit"
                  className="p-2 bg-white text-black font-semibold rounded hover:bg-gray-200 transition-colors"
                >
                  Subscribe
                </button>
              </form>
              <div className="mt-4 flex">
                <a
                  href={`https://www.facebook.com/profile.php?id=${aboutData?.facebookId}` || 'https://facebook.com'}
                  className="mx-2 hover:underline flex items-center"
                >
                  <FaFacebookF className="mr-2 text-xl" />
                </a>
                <a
                  href={`https://instagram.com/${aboutData?.instagramId}` || 'https://instagram.com'}
                  className="mx-2 hover:underline flex items-center"
                >
                  <FaInstagram className="mr-2 text-xl" />
                </a>
              </div>
            </div>
          </div>

          <div className="mt-8 border-t border-gray-700 pt-8 text-center">
  <p>&copy; {new Date().getFullYear()} {aboutData?.companyName || 'Your Company'}. All Rights Reserved.</p>
  <p className="mt-2">Powered by <a href="https://biztoindia.com/" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">Bizto India</a></p>
</div>

        </div>
      </footer>

      {/* Floating Icons Component */}
      <FloatingIcons />
    </>
  );
};

export default Footer;
