import React, { useEffect, useState } from 'react';
import { toast, Toaster } from 'react-hot-toast'; // Import react-hot-toast

const PrivacyPolicyShow = () => {
  const [privacyContent, setPrivacyContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    // Fetch privacy content from the API
    const fetchPrivacyContent = async () => {
      toast.loading('Fetching Privacy Policy...'); // Show loading toast
      
      try {
        const response = await fetch('https://boostapi.biztoindia.in/api/get-privacy');
        
        if (!response.ok) {
          throw new Error('Failed to fetch privacy content');
        }

        const data = await response.json();
        setPrivacyContent(data.privacy.content);
        toast.dismiss(); // Dismiss loading toast
        toast.success('Privacy Policy loaded successfully!'); // Show success toast
        setLoading(false);
      } catch (error) {
        setError(error.message);
        toast.dismiss(); // Dismiss loading toast
        toast.error('Error fetching Privacy Policy'); // Show error toast
        setLoading(false);
      }
    };

    fetchPrivacyContent();
  }, []);

  if (loading) {
    return <div className="text-center text-blue-500 text-lg mt-10">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500 text-lg mt-10">Error: {error}</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded shadow-md mt-10">
      <Toaster position="top-right" /> {/* Add Toaster to show toast notifications */}
      
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-6">Privacy Policy</h1>
      
      <div className="privacy-content text-gray-700 leading-relaxed">
        {privacyContent ? (
          <div dangerouslySetInnerHTML={{ __html: privacyContent }} className="prose max-w-none" />
        ) : (
          <p>No privacy policy content available.</p>
        )}
      </div>
    </div>
  );
};

export default PrivacyPolicyShow;
