import React, { useEffect, useState } from 'react';
import SummaryApi from '../common';
import { Link } from 'react-router-dom';
import displayINRCurrency from '../helpers/displayCurrency';
import ContactForm from '../components/Enquiry';
import { Helmet } from 'react-helmet';
import CategoryList from '../components/CategoryList';
 
const ShowAllProducts = () => {
  const [allProduct, setAllProduct] = useState([]);

  const fetchAllProduct = async () => {
    const response = await fetch(SummaryApi.allProduct.url);
    const dataResponse = await response.json();

    // console.log("product data", dataResponse);

    setAllProduct(dataResponse?.data || []);
  };

  useEffect(() => {
    fetchAllProduct();
  }, []);

  return (
    <>
     <Helmet>
      <title>All Products</title>
     </Helmet>
    
    
    <div className="container mx-auto p-4">
    <CategoryList/>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {allProduct?.map((product) => (
          <Link to={`/product/${product?._id}`} key={product?._id} className="bg-white shadow-lg rounded-lg p-4 transition-transform transform hover:scale-105 hover:shadow-2xl">
            <div className="bg-slate-200 h-48 p-4 min-w-[280px] flex justify-center items-center">
              <img
                src={product?.productImage[0]}
                alt={product?.productName}
                className="object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply"
              />
            </div>
            <div className="p-4 grid gap-3">
              <h2 className="font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black">
                {product?.productName}
              </h2>
              <p className="capitalize text-slate-500">{product?.category}</p>
              <div className="flex gap-3">
                <p className="text-red-600 font-medium">{displayINRCurrency(product?.sellingPrice)}</p>
                <p className="text-slate-500 line-through">{displayINRCurrency(product?.price)}</p>
              </div>
              <div onClick={(e) => e.preventDefault() && e.stopPropagation()}>
                <ContactForm className="bg-blue-500 text-white py-2 px-4 rounded" />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
    </>
  );
};

export default ShowAllProducts;
