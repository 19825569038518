import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import toast, { Toaster } from 'react-hot-toast';

const ContactIntegration = () => {
  const [contactData, setContactData] = useState({
    phone: '',
    email: '',
    address: '',
    googleMapIframe: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  // Fetch existing contact details when component mounts
  useEffect(() => {
    const fetchContactDetails = async () => {
      try {
        const response = await fetch('https://boostapi.biztoindia.in/api/get-contact');
        if (!response.ok) {
          // throw new Error('Error fetching contact details.');
          toast.error("Error fetching contact details.");
        }
        const data = await response.json();
        setContactData(data.data);
      } catch (err) {
        setError(err.message);
        toast.error(err.message);
      }
    };

    fetchContactDetails();
  }, []);

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactData({ ...contactData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      const response = await fetch('https://boostapi.biztoindia.in/api/contact-det', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(contactData),
      });

      if (!response.ok) {
        toast.error('Error updating contact details.')
      
      }

      toast.success('Contact details updated successfully!');
    } catch (err) {
      // setError(err.message);
      toast.error(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <Helmet>
        <title>Update Contact Information</title>
      </Helmet>

      <h1 className="text-3xl font-bold text-center mb-6">Update Contact Information</h1>

      <form className="bg-white p-6 rounded-lg shadow-md max-w-3xl mx-auto" onSubmit={handleSubmit}>
        <div className="grid gap-6 mb-4 md:grid-cols-2">
          <div>
            <label className="block text-gray-700 font-semibold mb-2">Phone</label>
            <input
              type="text"
              name="phone"
              value={contactData.phone}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-600"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 font-semibold mb-2">Email</label>
            <input
              type="email"
              name="email"
              value={contactData.email}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-600"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 font-semibold mb-2">Address</label>
            <input
              type="text"
              name="address"
              value={contactData.address}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-600"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 font-semibold mb-2">Google Map Iframe</label>
            <input
              type="text"
              name="googleMapIframe"
              value={contactData.googleMapIframe}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-600"
              required
            />
          </div>
        </div>
        {error && <p className="text-red-600 text-center mb-4">{error}</p>}
        <button
          type="submit"
          className={`w-full py-3 text-white bg-red-600 rounded-lg hover:bg-red-700 transition-all ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Updating...' : 'Update Contact Details'}
        </button>
      </form>

      <Toaster position="top-center" />
    </div>
  );
};

export default ContactIntegration;
