const url = `https://api.cloudinary.com/v1_1/dxbwmvzhb/image/upload`

const uploadImage  = async(image) => {
    const formData = new FormData()
    formData.append("file",image)
    formData.append("upload_preset","mern_product")
    // console.log("f",formData)
   
    const dataResponse = await fetch(url,{
        method : "post",
        body : formData
    })
   console.log("datares",dataResponse)
    return dataResponse.json()

}

export default uploadImage 