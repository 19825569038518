import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SummaryApi from '../common';
import { FaStar, FaStarHalf } from 'react-icons/fa';
import displayINRCurrency from '../helpers/displayCurrency';
import CategroyWiseProductDisplay from '../components/CategoryWiseProductDisplay';
import { Helmet } from 'react-helmet';
import { toast, Toaster } from 'react-hot-toast';

const ProductDetails = () => {
  const [data, setData] = useState({
    productName: "",
    brandName: "",
    category: "",
    productImage: [],
    description: "",
    price: "",
    sellingPrice: ""
  });
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [activeImage, setActiveImage] = useState("");
  const [zoomImageCoordinate, setZoomImageCoordinate] = useState({ x: 0, y: 0 });
  const [zoomImage, setZoomImage] = useState(false);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '', mobile: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchProductDetails = async () => {
    setLoading(true);
    const response = await fetch(SummaryApi.productDetails.url, {
      method: SummaryApi.productDetails.method,
      headers: { "content-type": "application/json" },
      body: JSON.stringify({ productId: params?.id })
    });
    setLoading(false);
    const dataResponse = await response.json();
    setData(dataResponse?.data);
    setActiveImage(dataResponse?.data?.productImage[0]);
  };

  useEffect(() => {
    fetchProductDetails();
  }, [params]);

  const handleMouseEnterProduct = (imageURL) => {
    setActiveImage(imageURL);
  };

  const handleZoomImage = useCallback((e) => {
    setZoomImage(true);
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = (e.clientX - left) / width;
    const y = (e.clientY - top) / height;
    setZoomImageCoordinate({ x, y });
  }, []);

  const handleLeaveImageZoom = () => {
    setZoomImage(false);
  };

  const handlePopupOpen = () => setIsPopupOpen(true);
  const handlePopupClose = () => setIsPopupOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const emailData = {
      to: formData.email,
      subject: `Enquiry from ${formData.name}`,
      text: `Name: ${formData.name}\nMobile: ${formData.mobile}\nEmail: ${formData.email}`,
      html: `<p><strong>Name:</strong> ${formData.name}</p><p><strong>Mobile:</strong> ${formData.mobile}</p><p><strong>Email:</strong> ${formData.email}</p>`,
      product: data,
    };

    try {
      const response = await fetch('https://boostapi.biztoindia.in/api/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(emailData),
      });

      if (response.ok) {
        toast.success('Email sent successfully!');
        setFormData({ name: '', email: '', mobile: '' });
        setIsPopupOpen(false);
      } else {
        toast.error('Error sending email.');
      }
    } catch (error) {
      toast.error('Error sending email.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Function to extract number and text
  function extractNumberAndText(input) {
    const regex = /^(\d+)\s*(.*)$/;
    const match = input.match(regex);

    if (match) {
      const number = match[1];
      const text = match[2];
      return { number, text };
    }

    return null;
  }

  // Extract number from data.price
  const extractedData = extractNumberAndText(data.price);
  const extractedNumber = extractedData ? extractedData.number : data.price;
  const extractedText= extractedData ? extractedData.text:'';

  const data1 = {
    metaTitle: 'Contact Us | Your Company',
    metaDescription: 'Get in touch with us for any inquiries or support.',
    metaKeywords: 'contact, support, inquiry, help'
  };

  // Fallback values for SEO
  const fallbackMetaTitle =  data.productName;
  const fallbackMetaDescription =  data.description;
  const fallbackMetaKeywords =  data.description;
  
  const displayINRCurrency1 = (num) => {
    const formatter = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 2,
    });

    return `Starting From: ${formatter.format(num)}`;
  };

  return (
    <> 
      <Helmet>
        <title>{data?.metaTitle || fallbackMetaTitle}</title>
        <meta name="description" content={data?.metaDescription || fallbackMetaDescription} />
        <meta name="keywords" content={data?.metaKeywords || fallbackMetaKeywords} />
      </Helmet>
      <div className='container mx-auto p-4'>
        <Toaster position="top-right" reverseOrder={false} />
        <div className='min-h-[200px] flex flex-col lg:flex-row gap-4'>
          <div className='h-96 flex flex-col lg:flex-row-reverse gap-4'>
            <div className='h-[300px] w-[300px] lg:h-96 lg:w-96 bg-slate-200 relative p-2'>
              <img
                src={activeImage}
                className='h-full w-full object-scale-down mix-blend-multiply'
                onMouseMove={handleZoomImage}
                onMouseLeave={handleLeaveImageZoom}
              />
              {zoomImage && (
                <div className='hidden lg:block absolute min-w-[500px] overflow-hidden min-h-[400px] bg-slate-200 p-1 -right-[510px] top-0'>
                  <div
                    className='w-full h-full min-h-[400px] min-w-[500px] mix-blend-multiply scale-150'
                    style={{
                      background: `url(${activeImage})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: `${zoomImageCoordinate.x * 100}% ${zoomImageCoordinate.y * 100}% `
                    }}
                  />
                </div>
              )}
            </div>

            <div className='h-full'>
              {/* Product Image Thumbnails */}
              <div className='flex gap-2 lg:flex-col overflow-scroll scrollbar-none h-full'>
                {loading ? (
                  <div className='animate-pulse'>Loading...</div>
                ) : (
                  data?.productImage?.map((imgURL) => (
                    <div className='h-20 w-20 bg-slate-200 rounded p-1' key={imgURL}>
                      <img
                        src={imgURL}
                        className='w-full h-full object-scale-down mix-blend-multiply cursor-pointer'
                        onMouseEnter={() => handleMouseEnterProduct(imgURL)}
                      />
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>

          {loading ? (
            <div>Loading Product Details...</div>
          ) : (
            <div className='flex flex-col gap-1'>
              <p className='bg-red-200 text-red-600 px-2 rounded-full inline-block w-fit'>{data?.brandName}</p>
              <h2 className='text-2xl lg:text-4xl font-medium'>{data?.productName}</h2>
              <h2 className='capitalize text-slate-400'>{data?.category}</h2>
              
              <div className='flex items-center gap-2 text-2xl lg:text-3xl font-medium my-1'>
              <p className="text-gray-800">
  
              <span className="text-red-600 font-bold">{displayINRCurrency1(extractedNumber)}</span> 
              <span className="text-gray-600"><span className='text-bold'>/</span> {extractedText}</span>
</p>

              </div>

              <button onClick={handlePopupOpen} className='bg-red-500 hover:bg-red-600 transition-all text-white px-4 mt-3 py-2 w-60 rounded'>
                Enquiry Now
              </button>
              <div>
                <p className='text-slate-600 font-medium my-1'>Description : </p>
                <p>{data?.description}</p>
              </div>
              {/* Popup Form */}
              {isPopupOpen && (
                <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
                  <div className='bg-white rounded-lg shadow-lg p-8 max-w-md w-full relative'>
                    <button onClick={handlePopupClose} className='absolute top-2 right-2 text-2xl font-bold text-gray-600'>×</button>
                    <h2 className='text-xl font-semibold mb-4'>Enquiry Form</h2>
                    <form onSubmit={handleSubmit}>
                      <div className='mb-4'>
                        <label className='block text-gray-700 text-sm font-bold mb-2'>Name</label>
                        <input
                          type='text'
                          name='name'
                          value={formData.name}
                          onChange={handleChange}
                          className='w-full px-3 py-2 border border-gray-300 rounded-md'
                          required
                        />
                      </div>
                      <div className='mb-4'>
                        <label className='block text-gray-700 text-sm font-bold mb-2'>Email</label>
                        <input
                          type='email'
                          name='email'
                          value={formData.email}
                          onChange={handleChange}
                          className='w-full px-3 py-2 border border-gray-300 rounded-md'
                          required
                        />
                      </div>
                      <div className='mb-4'>
                        <label className='block text-gray-700 text-sm font-bold mb-2'>Mobile</label>
                        <input
                          type='tel'
                          name='mobile'
                          value={formData.mobile}
                          onChange={handleChange}
                          className='w-full px-3 py-2 border border-gray-300 rounded-md'
                          required
                        />
                      </div>
                      <button
                        type='submit'
                        className='bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded'
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? 'Submitting...' : 'Submit'}
                      </button>
                    </form>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <CategroyWiseProductDisplay category={data?.category}  />
      </div>
    </>
  );
};

export default ProductDetails;
