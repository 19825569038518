import React, { useState, useEffect } from 'react';
import { MdDelete } from 'react-icons/md';
import SummaryApi from '../common';
import { toast } from 'react-toastify';

const AllCategoryUpdate = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(SummaryApi.allcategory.url, {
          method: SummaryApi.allcategory.method,
          credentials: 'include',
          headers: {
            "Content-Type": "application/json"
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        if (responseData.success) {
          setCategories(responseData.allCategories);
        } else {
          toast.error(responseData.message);
        }
      } catch (error) {
        toast.error("Failed to fetch categories. Please check the console for details.");
        // console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleDeleteCategory = async (categoryId) => {
    // Optimistically remove the category from UI
    const updatedCategories = categories.filter(category => category._id !== categoryId);
    setCategories(updatedCategories);

    try {
      const response = await fetch(`${SummaryApi.deleteCategory.url}/${categoryId}`, {
        method: SummaryApi.deleteCategory.method,
        credentials: 'include',
        headers: {
          "Content-Type": "application/json"
        }
      });

      const responseData = await response.json();

      if (responseData.success) {
        toast.success(responseData.message);
      } else {
        // If deletion fails, restore the category
        setCategories(categories);
        toast.error(responseData.message);
      }
    } catch (error) {
      // Restore the category and show error
      setCategories(categories);
      toast.error("Failed to delete category. Please check the console for details.");
      // console.error("Error deleting category:", error);
    }
  };

  return (
    <div className='p-4'>
      <h1 className='text-xl font-bold mb-4'>All Categories</h1>
      <div className='grid grid-cols-1 gap-4'>
        {categories.map((category) => (
          <div key={category._id} className='bg-white p-4 rounded border'>
            <div className='flex justify-between items-center'>
              <h2 className='text-lg font-semibold'>{category.value}</h2>
              <MdDelete
                className='text-red-600 cursor-pointer'
                onClick={() => handleDeleteCategory(category._id)}
              />
            </div>
          
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllCategoryUpdate;
