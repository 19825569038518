import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import toast, { Toaster } from 'react-hot-toast'; // Import toast from react-hot-toast

// Custom toolbar options
const toolbarOptions = [
  [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
  [{ size: [] }],
  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  [{ 'script': 'sub' }, { 'script': 'super' }],
  [{ 'indent': '-1' }, { 'indent': '+1' }],
  [{ 'direction': 'rtl' }],
  [{ 'color': [] }, { 'background': [] }],
  [{ 'align': [] }],
  ['link', 'image', 'video'],
  ['clean'],
];

const PrivacyAndPolicy = () => {
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);

  const handleContentChange = (value) => {
    setContent(value);
  };

  const handleSubmit = async () => {
    if (!content.trim()) {
      toast.error('Content cannot be empty'); // Show error toast
      return;
    }

    setLoading(true);
    try {
      const response = await fetch('https://boostapi.biztoindia.in/api/privacy-put', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ content }),
      });

      if (response.ok) {
        toast.success('Privacy content updated successfully'); // Show success toast
      } else {
        toast.error('Failed to update content'); // Show error toast
      }
    } catch (error) {
      toast.error('An error occurred while updating the content'); // Show error toast
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Update Privacy & Policy</h1>

      <ReactQuill
        value={content}
        onChange={handleContentChange}
        className="mb-4"
        theme="snow"
        placeholder="Write the privacy policy content here..."
        modules={{
          toolbar: toolbarOptions, // Use the customized toolbar
        }}
      />

      <button
        onClick={handleSubmit}
        disabled={loading}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        {loading ? 'Updating...' : 'Update Privacy Policy'}
      </button>

      <Toaster /> {/* Toaster component for rendering toast notifications */}
    </div>
  );
};

export default PrivacyAndPolicy;
