import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Toaster, toast } from 'react-hot-toast';
 
const ShowGallery = () => {
  
  const [files, setFiles] = useState([]);

  // Fetch files from the API
  const fetchFiles = async () => {
    try {
      const response = await fetch('https://boostapi.biztoindia.in/api/files');
      const data = await response.json();
      setFiles(data);
    } catch (error) {
      // toast.error('Failed to load files');
      // console.error('Error fetching files:', error);
    }
  };

  useEffect(() => {
    fetchFiles(); // Load files on component mount
  }, []);

  // Render different file types
  const renderFile = (file) => {
    const fileType = file.mimeType.split('/')[0];

    switch (fileType) {
      case 'image':
        return (
          <img
            src={`https://boostapi.biztoindia.in/${file.path}`}
            alt={file.originalName}
            className="w-full h-full object-cover rounded-lg shadow-lg"
          />
        );
      case 'video':
        return (
          <video
            controls
            className="w-full h-full object-cover rounded-lg shadow-lg"
          >
            <source src={`https://boostapi.biztoindia.in/${file.path}`} type={file.mimeType} />
            Your browser does not support the video tag.
          </video>
        );
      case 'audio':
        return (
          <audio
            controls
            className="w-full h-full object-cover rounded-lg shadow-lg"
          >
            <source src={`https://boostapi.biztoindia.in/${file.path}`} type={file.mimeType} />
            Your browser does not support the audio element.
          </audio>
        );
      case 'application':
        return (
          <a
            href={`https://boostapi.biztoindia.in/${file.path}`}
            target="_blank"
            rel="noopener noreferrer"
            className="w-full h-full flex items-center justify-center rounded-lg shadow-lg bg-gray-200 text-gray-700 hover:bg-gray-300"
          >
            <p className="text-center">View Document</p>
          </a>
        );
      default:
        return (
          <div className="w-full h-full flex items-center justify-center rounded-lg shadow-lg bg-gray-200 text-gray-700">
            <p>Unsupported file type</p>
          </div>
        );
    }
  };

  return (
    <> 
    <Helmet> 
      <title>Gallery </title>
    </Helmet>
<div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <Toaster position="top-center" reverseOrder={false} />
      
      {/* Responsive grid for displaying media */}
      <div className={`grid ${files.length === 1 ? 'grid-cols-1' : 'grid-cols-2 md:grid-cols-3 lg:grid-cols-4'} gap-4 w-full`}>
        {files.map((file, index) => (
          <div
            key={index}
            className={`relative ${files.length === 1 ? 'w-full h-screen' : 'w-full h-48 md:h-64'}`}
          >
            {renderFile(file)}
          </div>
        ))}
      </div>
    </div>
    </>
    
  );
};

export default ShowGallery;
