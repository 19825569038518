import React, { useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { FaUpload } from 'react-icons/fa'; // Import the upload icon
import uploadImage from '../helpers/uploadImage'; // Import the uploadImage function

const BannerUpdate = () => {
  const [desktopImages, setDesktopImages] = useState([]);
  const [mobileImages, setMobileImages] = useState([]);

  const handleFileChange = (e, setImage) => {
    setImage(Array.from(e.target.files));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (desktopImages.length === 0 || mobileImages.length === 0) {
      toast.error('Please upload both desktop and mobile images.');
      return;
    }

    try {
      const desktopImageUrls = await Promise.all(desktopImages.map(file => uploadImage(file)));
      const mobileImageUrls = await Promise.all(mobileImages.map(file => uploadImage(file)));

      const response = await fetch('https://boostapi.biztoindia.in/api/upload-banner', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          desktopImages: desktopImageUrls.map(res => res.secure_url),
          mobileImages: mobileImageUrls.map(res => res.secure_url),
        }),
      });

      if (response.ok) {
        toast.success('Images uploaded and saved successfully.');
        // Clear the form by resetting the state
        setDesktopImages([]);
        setMobileImages([]);
      } else {
        toast.error('Failed to save images.');
      }

    } catch (error) {
      toast.error('An error occurred during upload.');
      console.error(error);
    }
  };

  return (
    <div className="p-6 max-w-lg mx-auto bg-white shadow-lg rounded-lg">
      <Toaster position="top-right" reverseOrder={false} />
      <h1 className="text-2xl font-bold mb-4 text-center">Banner Update</h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="flex flex-col space-y-3">
          <label htmlFor="desktopImages" className="font-medium">Desktop Images:</label>
          <input
            type="file"
            id="desktopImages"
            accept="image/*"
            multiple
            onChange={(e) => handleFileChange(e, setDesktopImages)}
            className="p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <div className="grid grid-cols-2 gap-2">
            {desktopImages.map((file, index) => (
              <img
                key={index}
                src={URL.createObjectURL(file)}
                alt={`Desktop Preview ${index + 1}`}
                className="w-24 h-24 object-cover rounded border border-gray-300"
              />
            ))}
          </div>
        </div>
        <div className="flex flex-col space-y-3">
          <label htmlFor="mobileImages" className="font-medium">Mobile Images:</label>
          <input
            type="file"
            id="mobileImages"
            accept="image/*"
            multiple
            onChange={(e) => handleFileChange(e, setMobileImages)}
            className="p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <div className="grid grid-cols-2 gap-2">
            {mobileImages.map((file, index) => (
              <img
                key={index}
                src={URL.createObjectURL(file)}
                alt={`Mobile Preview ${index + 1}`}
                className="w-24 h-24 object-cover rounded border border-gray-300"
              />
            ))}
          </div>
        </div>
        <button
          type="submit"
          className="w-full py-3 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition"
        >
          Upload
        </button>
      </form>
    </div>
  );
};

export default BannerUpdate;
