import React, { useState, useEffect } from 'react';
import CategoryList from '../components/CategoryList';
import BannerProduct from '../components/BannerProduct';
import HorizontalCardProduct from '../components/HorizontalCardProduct';
import VerticalCardProduct from '../components/VerticalCardProduct';
import SummaryApi from '../common'; // Assuming this has the API endpoint
import { Helmet } from 'react-helmet';
import AboutUs from './AboutUs';
import { toast } from 'react-toastify';

// Loader Component
const Loader = () => (
  <div className="flex items-center justify-center h-screen">
    <div className="loader"></div> {/* Add your loader styles here */}
  </div>
);

const Home = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(SummaryApi.allcategory.url, {
          method: SummaryApi.allcategory.method,
          credentials: 'include',
          headers: {
            "Content-Type": "application/json"
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        
        if (responseData.success) {
          setCategories(responseData.allCategories);
        } else {
          toast.success(responseData.message)
          // console.error(responseData.message);
        }
      } catch (error) {
        // console.error("Error fetching categories:", error);
        // toast.error("Error fetching categories:")
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchCategories();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Home </title>
        <meta name="description" content="Welcome to our store. Discover a wide variety of products across different categories including electronics, fashion, home decor, and more." />
        <meta name="keywords" content="store, products, electronics, fashion, home decor, online shopping" />
        <meta name="robots" content="index, follow" />
      </Helmet>
    
      {loading ? (
         <div className="flex items-center justify-center h-screen">
         <div className="w-8 h-8 border-4 border-t-4 border-gray-200 border-t-gray-800 rounded-full animate-spin"></div>
       </div>
      ) : (
        <>
          <BannerProduct />
          <AboutUs/>
          {/* Render VerticalCardProduct for each category */}
          {categories?.map(cat => (
            <VerticalCardProduct
              key={cat.value}
              category={cat.value}
              heading={cat.label}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default Home;
