import React, { useEffect, useState } from 'react';
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";

const BannerProduct = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const [desktopImages, setDesktopImages] = useState([]);
    const [mobileImages, setMobileImages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);  // Add a loading state

    // Fetch the banner images from the API only once
    useEffect(() => {
        const fetchBannerImages = async () => {
            try {
                // Only fetch if no images are loaded yet
                if (desktopImages.length === 0 && mobileImages.length === 0) {
                    const response = await fetch('https://boostapi.biztoindia.in/api/get-banner');
                    const data = await response.json();

                    // Check if data exists and has images
                    if (Array.isArray(data?.data) && data.data.length > 0) {
                        setDesktopImages(data.data[0].desktopImages);
                        setMobileImages(data.data[0].mobileImages);
                    }
                }
            } catch (error) {
                console.error('Error fetching banner images:', error);
            } finally {
                setIsLoading(false);  // Stop loading once the fetch is complete
            }
        };

        fetchBannerImages();
    }, []);  // Empty dependency array to ensure this runs only once on mount

    // Move to the next image
    const nextImage = () => {
        if (desktopImages.length - 1 > currentImage) {
            setCurrentImage(prev => prev + 1);
        }
    };

    // Move to the previous image
    const prevImage = () => {
        if (currentImage !== 0) {
            setCurrentImage(prev => prev - 1);
        }
    };

    // Auto-switch images every 5 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            if (desktopImages.length - 1 > currentImage) {
                nextImage();
            } else {
                setCurrentImage(0);
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [currentImage, desktopImages]);

    // Hide the component if no images are available after loading
    if (!isLoading && desktopImages.length === 0 && mobileImages.length === 0) {
        return null;
    }

    return (
        <div className='container mx-auto px-4 rounded'>
            <div className='h-56 md:h-72 w-full bg-slate-200 relative'>
                {/* Desktop controls */}
                <div className='absolute z-10 h-full w-full md:flex items-center hidden'>
                    <div className='flex justify-between w-full text-2xl'>
                        <button onClick={prevImage} className='bg-white shadow-md rounded-full p-1'><FaAngleLeft /></button>
                        <button onClick={nextImage} className='bg-white shadow-md rounded-full p-1'><FaAngleRight /></button>
                    </div>
                </div>

                {/* Desktop and tablet version */}
                {desktopImages.length > 0 && (
                    <div className='hidden md:flex h-full w-full overflow-hidden'>
                        {desktopImages.map((imageURL, index) => (
                            <div
                                className='w-full h-full min-w-full min-h-full transition-all'
                                key={`desktop-${index}`}
                                style={{ transform: `translateX(-${currentImage * 100}%)` }}
                            >
                                <img src={imageURL} alt={`Desktop Banner ${index}`} className='w-full h-full object-cover' />
                            </div>
                        ))}
                    </div>
                )}

                {/* Mobile version */}
                {mobileImages.length > 0 && (
                    <div className='flex h-full w-full overflow-hidden md:hidden'>
                        {mobileImages.map((imageURL, index) => (
                            <div
                                className='w-full h-full min-w-full min-h-full transition-all'
                                key={`mobile-${index}`}
                                style={{ transform: `translateX(-${currentImage * 100}%)` }}
                            >
                                <img src={imageURL} alt={`Mobile Banner ${index}`} className='w-full h-full object-cover' />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default BannerProduct;
