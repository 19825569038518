import React, { useState, useRef } from 'react';
import { Toaster, toast } from 'react-hot-toast';

const UploadGallery = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null); // Ref for the file input element

  // Handle file selection
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Handle file upload
  const handleUpload = async (e) => {
    e.preventDefault();

    if (!file) {
      toast.error('Please select a file first!');
      return;
    }

    const formData = new FormData();
    formData.append('file', file); // 'file' is the key used in the multer setup

    setLoading(true); // Set loading to true during upload

    try {
      const response = await fetch('https://boostapi.biztoindia.in/api/upload', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        toast.success('File uploaded successfully!');
        // console.log(await response.json());
        
        // Reset the file input field and state
        fileInputRef.current.value = ''; // Reset the file input field in the DOM
        setFile(null); // Reset the file state
      } else {
        toast.error('File upload failed!');
        // console.error('Error uploading file:', await response.json());
      }
    } catch (error) {
      toast.error('File upload failed!');
      // console.error('Error uploading file:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <Toaster position="top-center" reverseOrder={false} />
      <h1 className="text-2xl font-bold mb-6 text-gray-700">Upload to Gallery</h1>
      <form className="w-full max-w-md bg-white p-6 rounded-lg shadow-lg" onSubmit={handleUpload}>
        <label className="block text-gray-700 font-bold mb-2">
          Choose a file:
          <input
            ref={fileInputRef} // Reference the file input field
            type="file"
            onChange={handleFileChange}
            className="block w-full text-sm text-gray-500 border border-gray-300 rounded-md p-2 mt-2"
          />
        </label>
        <button
          type="submit"
          className={`w-full py-2 px-4 mt-4 text-white font-semibold rounded-md ${loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'}`}
          disabled={loading}
        >
          {loading ? 'Uploading...' : 'Upload'}
        </button>
      </form>
    </div>
  );
};

export default UploadGallery;
