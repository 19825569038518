import React, { useState, useEffect } from 'react';

const FaqShow = () => {
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch FAQs from the API
  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await fetch('https://boostapi.biztoindia.in/api/get-faq');
        if (!response.ok) {
          throw new Error('Failed to fetch FAQs');
        }
        const data = await response.json();
        setFaqs(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchFaqs();
  }, []);

  if (loading) {
    return <p className="text-center text-gray-600">Loading FAQs...</p>;
  }

  if (error) {
    return <p className="text-center text-red-600">Error: {error}</p>;
  }

  return (
    <div className="min-h-screen bg-gray-100 p-4 flex flex-col items-center">
      <h1 className="text-2xl font-bold mb-6">Frequently Asked Questions</h1>
      <div className="w-full max-w-4xl space-y-4">
        {faqs.length > 0 ? (
          faqs.map((faq) => (
            <div key={faq._id} className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-xl font-semibold mb-2">{faq.question}</h2>
              <p className="text-gray-700">{faq.answer}</p>
              <p className="text-gray-500 text-sm mt-4">
                Posted on: {new Date(faq.createdAt).toLocaleString()}
              </p>
            </div>
          ))
        ) : (
          <p className="text-gray-600">No FAQs available</p>
        )}
      </div>
    </div>
  );
};

export default FaqShow;
