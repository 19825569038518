 // EnquiriesTable.js
import React, { useEffect, useState } from 'react';

const EnquiriesTable = () => {
  const [enquiries, setEnquiries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEnquiries = async () => {
      try {
        const response = await fetch('https://boostapi.biztoindia.in/api/get-enquiry');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setEnquiries(data.data);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch enquiries');
        setLoading(false);
      }
    };

    fetchEnquiries();
  }, []);

  if (loading) return <p className="text-center text-gray-500">Loading...</p>;
  if (error) return <p className="text-center text-red-500">{error}</p>;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Enquiries</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300 rounded-md shadow-md">
          <thead className="bg-gray-200 text-gray-700">
            <tr>
              <th className="px-4 py-2 border-b">Name</th>
              <th className="px-4 py-2 border-b">Mobile</th>
              <th className="px-4 py-2 border-b">Email</th>
              <th className="px-4 py-2 border-b">Product Name</th>
              <th className="px-4 py-2 border-b">Date</th>
            </tr>
          </thead>
          <tbody>
            {enquiries?.map((enquiry) => (
              <tr key={enquiry._id} className="hover:bg-gray-100">
                <td className="px-4 py-2 border-b">{enquiry.name}</td>
                <td className="px-4 py-2 border-b">{enquiry.mobile}</td>
                <td className="px-4 py-2 border-b">{enquiry.email}</td>
                <td className="px-4 py-2 border-b">{enquiry?.product}</td>
                <td className="px-4 py-2 border-b">
  {new Date(enquiry.createdAt).toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false // 24-hour time format
  })}
</td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EnquiriesTable;
