import React, { useState } from 'react';
import './App.css'; // Import Tailwind CSS and custom styles

const ContactForm = ({product}) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
  });
// console.log("pppp",product)
 
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [notification, setNotification] = useState({ message: '', type: '' });

  // Handle form field changes
  const handleChange =  (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit =async  (e) => {
    e.preventDefault();  
    setIsSubmitting(true);
  
    const emailData = {
      to: formData.email,
      subject: 'New Website Visitor Enquiry from ' + formData.name,
      text: `Name: ${formData.name}\nMobile: ${formData.mobile}\nEmail: ${formData.email}`,
      html: `
        <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #ddd; border-radius: 8px; background-color: #f4f4f4;">
          <h2 style="color: #333; font-size: 22px; border-bottom: 2px solid #e0e0e0; padding-bottom: 10px; margin-bottom: 20px;">New Visitor Enquiry</h2>
          <p style="color: #555; font-size: 16px; line-height: 1.6;">
            <strong style="color: #333;">Name:</strong> ${formData.name}
          </p>
          <p style="color: #555; font-size: 16px; line-height: 1.6;">
            <strong style="color: #333;">Mobile:</strong> ${formData.mobile}
          </p>
          <p style="color: #555; font-size: 16px; line-height: 1.6;">
            <strong style="color: #333;">Email:</strong> ${formData.email}
          </p>
          <p style="color: #555; font-size: 16px; line-height: 1.6; margin-top: 30px;">
            Thank you for your interest. We will get back to you shortly.
          </p>
          <div style="text-align: center; margin-top: 30px;">
            <a href="https://yourwebsite.com/contact" style="display: inline-block; padding: 10px 20px; background-color: #007bff; color: #fff; text-decoration: none; border-radius: 5px;">Visit Our Website</a>
          </div>
        </div>
      `,
      
    };
    
  // console.log("email",emailData)
    try {
      const response =await  fetch('https://boostapi.biztoindia.in/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
      });
  // console.log(response)
       
        showNotification('Email sent successfully!', 'success');
        setFormData({ name: '', email: '', mobile: '' });
        setIsPopupOpen(false);
     
    } catch (error) {
      showNotification('Error sending email.', 'error');
    } finally {
      setIsSubmitting(false);
    }
  };
  
  // Show notification and auto-hide it after 3 seconds
  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => {
      setNotification({ message: '', type: '' });
    }, 3000);
  };

  
  return (
    <div>
      <button
        onClick={() => setIsPopupOpen(true)}
        className="btn bg-red-500 hover:bg-red-600 transition-all duration-300 ease-in-out text-white py-2 px-6 rounded-full shadow-lg focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75"
      >
        Enquiry Now
      </button>

      {/* Modal */}
      {isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-opacity duration-300 ">
          <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full relative ">
            <button
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 transition-all"
              onClick={() => setIsPopupOpen(false)}
            >
              &times;
            </button>
            <h3 className="text-md text-left font-bold text-black mb-4  ">Get More Details on Email/WhatsApp</h3>

            {/* Form */}
            <form className="space-y-6 rounded-full " onSubmit={handleSubmit}>
            <div className="mb-1">
  <input
    type="text"
    name="name"
    placeholder="Name*"
    className="form-input block w-full border-b-2 border-gray-300 bg-gray-100 py-1 px-4 focus:outline-none focus:border-black transition-all"
    value={formData.name}
    onChange={handleChange}
    required
  />
</div>
<div  >
  <input
    type="email"
    name="email"
    placeholder="Email*"
    className="form-input block w-full border-b-2 border-gray-300 bg-gray-100 py-1 px-4 focus:outline-none focus:border-black transition-all"
    value={formData.email}
    onChange={handleChange}
    required
  />
</div>
<div  >
  <input
    type="tel"
    name="mobile"
    placeholder="Mobile*"
    className="form-input block w-full border-b-2 border-gray-300 bg-gray-100 py-1 px-4 focus:outline-none focus:border-black transition-all"
    value={formData.mobile}
    onChange={handleChange}
    required
  />
</div>

              <div  >
                <button
                  type="submit"
                  className={`w-full py-2 px-6 border  border-black rounded-full shadow-lg text-black font-bold transition-all duration-300 ease-in-out ${
                    isSubmitting ? 'bg-gray-400' : 'bg-white  hover:bg-black hover:border-red-700 hover:text-white'
                  } focus:outline-none focus:ring-2 focus:ring-blue-400`}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Notification */}
      {notification.message && (
        <div
          className={`fixed top-5 right-5 z-50 px-4 py-2 rounded-lg text-white shadow-md transition-all ${
            notification.type === 'success' ? 'bg-green-500' : 'bg-red-500'
          }`}
        >
          {notification.message}
        </div>
      )}
    </div>
  );
};

export default ContactForm;
