import React, { useState, useEffect } from 'react';
import { CgClose } from "react-icons/cg";
import { FaCloudUploadAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import uploadImage from '../helpers/uploadImage';
import SummaryApi from '../common';
import { toast } from 'react-toastify';

const UploadProduct = ({ onClose, fetchData }) => {
  const [data, setData] = useState({
    productName: "",
    brandName: "",
    category: "",
    productImage: [],
    description: "",
    price: "",
    sellingPrice: "",
    metaTitle: "",
    metaDescription: "",
    metaKeywords: "",
    priceUnit: "Per Piece", // Default unit
  });

  const [openFullScreenImage, setOpenFullScreenImage] = useState(false);
  const [fullScreenImage, setFullScreenImage] = useState("");
  const [showSEOFields, setShowSEOFields] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [categoryImage, setCategoryImage] = useState(null); // New state for category image
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(SummaryApi.allcategory.url, {
          method: SummaryApi.allcategory.method,
          credentials: 'include',
          headers: {
            "Content-Type": "application/json"
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        // console.log("Fetched categories:", responseData);

        if (responseData.success) {
          setCategories(responseData.allCategories);  
        } else {
          toast.error(responseData.message);
        }
      } catch (error) {
        // console.error("Error fetching categories:", error);
        toast.error("Failed to fetch categories. Please check the console for details.");
      }
    };

    fetchCategories();
  }, []);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handlePriceUnitChange = (e) => {
    setData((prev) => ({
      ...prev,
      priceUnit: e.target.value,
      price: prev.price.split(" ")[0] + " " + e.target.value // Update the price with the selected unit
    }));
  };

  const handleUploadProduct = async (e) => {
    const file = e.target.files[0];
    const uploadImageCloudinary = await uploadImage(file);

    setData((prev) => ({
      ...prev,
      productImage: [...prev.productImage, uploadImageCloudinary.url]
    }));
  };

  const handleCategoryImageUpload = async (e) => {
    const file = e.target.files[0];
    const uploadImageCloudinary = await uploadImage(file);
  
    // console.log('Uploaded category image URL:', uploadImageCloudinary.url); // Add this line
  
    setCategoryImage(uploadImageCloudinary.url);
  };
  
  const handleDeleteProductImage = async (index) => {
    const newProductImage = [...data.productImage];
    newProductImage.splice(index, 1);

    setData((prev) => ({
      ...prev,
      productImage: newProductImage
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Include new category if it was added
    const submitData = { ...data, category: newCategory || data.category };

    if (categoryImage) {
      submitData.categoryImage = categoryImage; // Add category image URL
    }

    const response = await fetch(SummaryApi.uploadProduct.url, {
      method: SummaryApi.uploadProduct.method,
      credentials: 'include',
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify(submitData)
    });

    const responseData = await response.json();

    if (responseData.success) {
      toast.success(responseData?.message);
      onClose();
      fetchData();
    }

    if (responseData.error) {
      toast.error(responseData?.message);
    }
  };

  const handleAddCategory = async () => {
    if (newCategory) {
      try {
        // Prepare the form data including the category name and image
        const categoryData = {
          name: newCategory,
          image: categoryImage || null, // Include category image URL if available
        };
  
        const response = await fetch(SummaryApi.createCateogory.url, {
          method: SummaryApi.createCateogory.method,
          credentials: 'include',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(categoryData)
        });
  
        const responseData = await response.json();
  
        if (responseData.success) {
          setCategories((prev) => [...prev, { value: newCategory, label: newCategory }]);
          setData((prev) => ({ ...prev, category: newCategory }));
          setNewCategory("");
          setCategoryImage(null); // Reset the category image state
          toast.success(responseData.message);
        } else {
          toast.error(responseData.message);
        }
      } catch (error) {
        toast.error("Failed to add category. Please check the console for details.");
        // console.error("Error adding category:", error);
      }
    }
  };

   

  return (
    <div className='fixed w-full h-full bg-slate-200 bg-opacity-35 top-0 left-0 right-0 bottom-0 flex justify-center items-center'>
      <div className='bg-white p-4 rounded w-full max-w-2xl h-full max-h-[80%] overflow-hidden'>
        <div className='flex justify-between items-center pb-3'>
          <h2 className='font-bold text-lg'>Upload Product</h2>
          <div className='w-fit ml-auto text-2xl hover:text-red-600 cursor-pointer' onClick={onClose}>
            <CgClose />
          </div>
        </div>

        <form className='grid p-4 gap-2 overflow-y-scroll h-full pb-5' onSubmit={handleSubmit}>
          {/* Product Name */}
          <label htmlFor='productName'>Product Name :</label>
          <input
            type='text'
            id='productName'
            placeholder='enter product name'
            name='productName'
            value={data.productName}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            required
          />

          {/* Brand Name */}
          <label htmlFor='brandName' className='mt-3'>Brand Name :</label>
          <input
            type='text'
            id='brandName'
            placeholder='enter brand name'
            value={data.brandName}
            name='brandName'
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            required
          />

          {/* Category */}
          <label htmlFor='category' className='mt-3'>Category :</label>
          <select required value={data.category} name='category' onChange={handleOnChange} className='p-2 bg-slate-100 border rounded'>
            <option value="">Select Category</option>
            
            {categories.map((el, index) => (
              <option value={el.value} key={el.value + index}>{el.label}</option>
            ))}
          </select>

          {/* New Category */}
          <label htmlFor='newCategory' className='mt-3'>New Category :</label>
          <input
            type='text'
            id='newCategory'
            placeholder='enter new category'
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            className='p-2 bg-slate-100 border rounded'
          />
            {/* Category Image */}
            <label htmlFor='categoryImage' className='mt-3'>Category Image :</label>
          <label htmlFor='uploadCategoryImageInput'>
            <div className='p-2 bg-slate-100 border rounded h-32 w-full flex justify-center items-center cursor-pointer'>
              <div className='text-slate-500 flex justify-center items-center flex-col gap-2'>
                <span className='text-4xl'><FaCloudUploadAlt /></span>
                <p className='text-sm'>Upload Category Image</p>
                <input type='file' id='uploadCategoryImageInput' className='hidden' onChange={handleCategoryImageUpload} />
              </div>
            </div>
          </label>
          {categoryImage && (
            <div className='mt-2'>
              <img src={categoryImage} alt='Category' className='w-32 h-32 object-cover' />
            </div>
          )}
          <button type='button' className='mt-2 p-2 bg-green-500 text-white rounded' onClick={handleAddCategory}>
            Add Category
          </button>

          {/* Description */}
          <label htmlFor='description' className='mt-3'>Description :</label>
          <textarea
            id='description'
            placeholder='enter product description'
            name='description'
            value={data.description}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          />

          {/* Price */}
          <label htmlFor='price' className='mt-3'>Price :</label>
          <input
            type='text'
            id='price'
            placeholder='enter product price'
            name='price'
            value={data.price}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            required
          />

          {/* Price Unit */}
          <label htmlFor='priceUnit' className='mt-3'>Price Unit :</label>
          <select id='priceUnit' value={data.priceUnit} onChange={handlePriceUnitChange} className='p-2 bg-slate-100 border rounded'>
            <option value="Piece">Per Piece</option>
            <option value="ml">Per ml</option>
            <option value="sq ft">Per sq ft</option>
            <option value="meter">Per meter</option>
            <option value="year">Per year</option>
          </select>

          {/* Selling Price */}
          <label htmlFor='sellingPrice' className='mt-3'>Selling Price :</label>
          <input
            type='text'
            id='sellingPrice'
            placeholder='enter selling price'
            name='sellingPrice'
            value={data.sellingPrice}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          />

          {/* Meta Title */}
          <label htmlFor='metaTitle' className='mt-3'>Meta Title :</label>
          <input
            type='text'
            id='metaTitle'
            placeholder='enter meta title'
            name='metaTitle'
            value={data.metaTitle}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          />

          {/* Meta Description */}
          <label htmlFor='metaDescription' className='mt-3'>Meta Description :</label>
          <textarea
            id='metaDescription'
            placeholder='enter meta description'
            name='metaDescription'
            value={data.metaDescription}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          />

          {/* Meta Keywords */}
          <label htmlFor='metaKeywords' className='mt-3'>Meta Keywords :</label>
          <input
            type='text'
            id='metaKeywords'
            placeholder='enter meta keywords'
            name='metaKeywords'
            value={data.metaKeywords}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          />

          {/* Product Images */}
          <label htmlFor='productImage' className='mt-3'>Product Images :</label>
          <label htmlFor='uploadProductImageInput'>
            <div className='p-2 bg-slate-100 border rounded h-32 w-full flex justify-center items-center cursor-pointer'>
              <div className='text-slate-500 flex justify-center items-center flex-col gap-2'>
                <span className='text-4xl'><FaCloudUploadAlt /></span>
                <p className='text-sm'>Upload Product Image</p>
                <input type='file' id='uploadProductImageInput' className='hidden' onChange={handleUploadProduct} />
              </div>
            </div>
          </label>

          {data.productImage.length > 0 && (
            <div className='mt-2 flex gap-2'>
              {data.productImage.map((img, index) => (
                <div key={index} className='relative'>
                  <img src={img} alt='Product' className='w-32 h-32 object-cover' />
                  <button type='button' onClick={() => handleDeleteProductImage(index)} className='absolute top-0 right-0 text-red-500'>
                    <MdDelete />
                  </button>
                </div>
              ))}
            </div>
          )}

          {/* Submit Button */}
          <button type='submit' className='mt-4 p-2 bg-blue-500 text-white rounded'>Submit</button>
        </form>
      </div>
    </div>
  );
};

export default UploadProduct;
