import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Importing icons for read more

const AboutUs = () => {
  const [aboutData, setAboutData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [showFullMission, setShowFullMission] = useState(false);
  const [showFullWhyChooseUs, setShowFullWhyChooseUs] = useState(false);

  // Fetch About Us data from the API
  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const response = await fetch('https://boostapi.biztoindia.in/api/aboutget'); // Replace with your actual API endpoint
        if (response.ok) {
          const data = await response.json();
          setAboutData(data);
        } else {
          throw new Error('Failed to fetch data');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAboutData();
  }, []);

  if (loading) {
    return <p className="text-center text-lg font-semibold">Loading...</p>;
  }

  if (error) {
    return <p className="text-center text-lg text-red-600">Error: {error}</p>;
  }

  return (
    <section className="bg-gradient-to-r from-blue-50 to-blue-100 py-16 px-4 sm:px-6 lg:px-8">
      <Helmet>
        <title>{aboutData?.companyName + ' About Us'} </title>
      </Helmet>

      <div className="max-w-7xl mx-auto text-center">
        <p className="mt-2 text-4xl leading-10 font-extrabold text-red-600 sm:text-5xl">
          {aboutData?.companyName || 'Empowering Your Online Shopping Experience'}
        </p>
        <p className="mt-6  w-full text-lg text-gray-700 mx-auto">
          {aboutData?.aboutUs || 'At our Ecommerce Store, we are committed to providing the best online shopping experience.'}
        </p>
      </div>

      <div className="mt-12 max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12">
        {/* Our Mission Section */}
        <div className="space-y-6 p-6 bg-white shadow-lg rounded-lg transition-transform transform hover:scale-105">
          <h3 className="text-3xl font-semibold text-red-600">Our Mission</h3>
          <p className="text-lg text-gray-800">
            {showFullMission
              ? aboutData?.ourMission || 'Our mission is to ensure that every customer finds what they need at an unbeatable value.'
              : `${(aboutData?.ourMission || 'Our mission is to ensure that every customer finds what they need at an unbeatable value.').substring(0, 200)}...`}
          </p>
          <button
            onClick={() => setShowFullMission(!showFullMission)}
            className="text-red-600 flex items-center font-semibold hover:text-blue-800 transition-all duration-300"
          >
            {showFullMission ? (
              <>
                <FaChevronUp className="mr-2" /> Show Less
              </>
            ) : (
              <>
                <FaChevronDown className="mr-2" /> Read More
              </>
            )}
          </button>
        </div>

        {/* Why Choose Us Section */}
        <div className="space-y-6 p-6 bg-white shadow-lg rounded-lg transition-transform transform hover:scale-105">
          <h3 className="text-3xl font-semibold text-red-600">Why Choose Us?</h3>
          <p className="text-lg text-gray-800">
            {showFullWhyChooseUs
              ? aboutData?.whyChooseUs || 'From high-quality products to fast delivery and easy returns, our platform is designed with the customer in mind.'
              : `${(aboutData?.whyChooseUs || 'From high-quality products to fast delivery and easy returns, our platform is designed with the customer in mind.').substring(0, 200)}...`}
          </p>
          <button
            onClick={() => setShowFullWhyChooseUs(!showFullWhyChooseUs)}
            className="text-red-600 flex items-center font-semibold hover:text-blue-800 transition-all duration-300"
          >
            {showFullWhyChooseUs ? (
              <>
                <FaChevronUp className="mr-2" /> Show Less
              </>
            ) : (
              <>
                <FaChevronDown className="mr-2" /> Read More
              </>
            )}
          </button>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
