 import React, { useContext, useState, useEffect } from 'react';
import Logo from './Logo';
import { GrSearch, GrMenu, GrClose } from "react-icons/gr"; // Importing icons
import { FaRegCircleUser } from "react-icons/fa6";
import { FaShoppingCart } from "react-icons/fa";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import { setUserDetails } from '../store/userSlice';
import ROLE from '../common/role';
import Context from '../context';

const Header = () => {
  const user = useSelector(state => state?.user?.user);
  const dispatch = useDispatch();
  const [menuDisplay, setMenuDisplay] = useState(false); // For dropdown menu
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false); // For mobile menu
  const context = useContext(Context);
  const navigate = useNavigate();
  const searchInput = useLocation();
  const URLSearch = new URLSearchParams(searchInput?.search);
  const searchQuery = URLSearch.getAll("q");
  const [search, setSearch] = useState(searchQuery);
// console.log("efvgojrng",user);
  const handleLogout = async () => {
    const fetchData = await fetch(SummaryApi.logout_user.url, {
      method: SummaryApi.logout_user.method,
      credentials: 'include'
    });

    const data = await fetchData.json();

    if (data.success) {
      toast.success(data.message);
      dispatch(setUserDetails(null));
      navigate("/");
    }

    if (data.error) {
      toast.error(data.message);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);

    if (value) {
      navigate(`/search?q=${value}`);
    } else {
      navigate("/search");
    }
  };

  const [logoUrl, setLogoUrl] = useState('');

  // Fetch the logo URL from the API
  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const response = await fetch('https://boostapi.biztoindia.in/api/load-logo');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const logos = await response.json();
        setLogoUrl(logos.path); // Assuming the most recent logo is the first in the array
      } catch (error) {
        // console.error('Error fetching logo:', error);
      }
    };

    fetchLogo();
  }, []);

  // Close the mobile menu on link click
  const handleLinkClick = () => {
    setMobileMenuOpen(false);
  };

  return (
    <header className='h-16 bg-white shadow-md fixed w-full z-40'>
      <div className='h-full container mx-auto flex items-center px-4 justify-between'>
        <div className="flex items-center justify-center w-24 h-12 overflow-hidden">
          {logoUrl ? (
            <img
              src={`https://boostapi.biztoindia.in/${logoUrl}`}
              alt="Logo"
              className="w-full h-full object-contain"
            />
          ) : (
            <Logo className="w-24 h-12" /> // Fallback or default logo
          )}
        </div>

        {/* Mobile Menu Toggle Button */}
        <div className='lg:hidden'>
          {mobileMenuOpen ? (
            <GrClose className='text-3xl cursor-pointer' onClick={() => setMobileMenuOpen(false)} />
          ) : (
            <GrMenu className='text-3xl cursor-pointer' onClick={() => setMobileMenuOpen(true)} />
          )}
        </div>

        {/* Desktop Search Input */}
        <div className='hidden lg:flex items-center flex-1 justify-between max-w-md border rounded-full focus-within:shadow-md'>
          <input
            type='text'
            placeholder='Search products...'
            className='w-full p-2 rounded-l-full outline-none'
            onChange={handleSearch}
            value={search}
          />
          <button className='bg-red-600 text-white p-2 rounded-r-full'>
            <GrSearch className='text-lg' />
          </button>
        </div>

        {/* Desktop Links */}
        <nav className='hidden lg:flex items-center space-x-4'>
          <Link to="/" className='text-gray-700 font-bold hover:text-red-600 transition'>Home</Link>
          <Link to="/about" className='text-gray-700 font-bold hover:text-red-600 transition'>About Us</Link>
          <Link to="/all-products" className='text-gray-700 font-bold hover:text-red-600 transition'>Products</Link>
          <Link to="/Gallery" className='text-gray-700 font-bold hover:text-red-600 transition'>Gallery</Link>
          <Link to="/blogs" className='text-gray-700 font-bold hover:text-red-600 transition'>Blogs</Link>
          <Link to="/contact" className='text-gray-700 font-bold hover:text-red-600 transition'>Contact Us</Link>
        </nav>

        {/* Mobile Menu */}
        {mobileMenuOpen && (
          <nav className='absolute top-16 left-0 w-full bg-white shadow-lg lg:hidden'>
            <ul className='flex flex-col items-center space-y-4 py-4'>
              <li>
                <Link to="/" className='text-gray-700 font-bold hover:text-red-600 transition' onClick={handleLinkClick}>Home</Link>
              </li>
              <li>
                <Link to="/about" className='text-gray-700 font-bold hover:text-red-600 transition' onClick={handleLinkClick}>About Us</Link>
              </li>
              <li>
                <Link to="/all-products" className='text-gray-700 font-bold hover:text-red-600 transition' onClick={handleLinkClick}>Products</Link>
              </li>
              <li>
                <Link to="/Gallery" className='text-gray-700 font-bold hover:text-red-600 transition' onClick={handleLinkClick}>Gallery</Link>
              </li>
              <li>
                <Link to="/blogs" className='text-gray-700 font-bold hover:text-red-600 transition' onClick={handleLinkClick}>Blogs</Link>
              </li>
              <li>
                <Link to="/contact" className='text-gray-700 font-bold hover:text-red-600 transition' onClick={handleLinkClick}>Contact Us</Link>
              </li>
            </ul>
          </nav>
        )}
 
        {/* <div className='hidden lg:flex items-center space-x-4'>
          <div className='relative flex items-center'>
            {user?._id && (
              <div className='text-2xl cursor-pointer' onClick={() => setMenuDisplay(prev => !prev)}>
                {user?.profilePic ? (
                  <img src={user?.profilePic} className='w-10 h-10 rounded-full' alt={user?.name} />
                ) : (
                  <FaRegCircleUser />
                )}
              </div>
            )}
            {menuDisplay && (
              <div className='absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-lg'>
                <nav className='flex flex-col'>
                  {user?.role === ROLE.ADMIN && (
                    <Link to={"/admin/all-products"} className='block px-4 py-2 text-gray-700 hover:bg-gray-100' onClick={() => setMenuDisplay(prev => !prev)}>Admin Panel</Link>
                  )}
                </nav>
              </div>
            )}
          </div>

          {user?._id && (
            <Link to={"/cart"} className='relative text-2xl'>
              <FaShoppingCart />
              <div className='bg-red-600 text-white text-sm w-5 h-5 rounded-full flex items-center justify-center absolute -top-2 -right-3'>
                {context?.cartProductCount}
              </div>
            </Link>
          )}

          <div>
            {user?._id ? (
              <button onClick={handleLogout} className='px-3 py-1 rounded-full text-white bg-red-600 hover:bg-red-700 transition'>Logout</button>
            ) : (
              <Link to={"/login"} className='px-3 py-1 rounded-full text-white bg-red-600 hover:bg-red-700 transition'>Login</Link>
            )}
          </div>
        </div>   */}
      </div>
    </header>
  );
};

export default Header;
