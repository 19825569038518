import React, { useState } from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import toast, { Toaster } from "react-hot-toast";

// Image Upload Component
const UploadLogo = () => {
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  // Function to handle image upload
  const handleImageUpload = async (e) => {
    e.preventDefault();

    if (!image) {
      toast.error("Please select an image");
      return;
    }

    const formData = new FormData();
    formData.append('logo', image);

    try {
      setIsUploading(true);

      // Upload the image to the server
      const response = await fetch("https://boostapi.biztoindia.in/api/update-logo", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (data.logo) {
        setImageUrl(`https://boostapi.biztoindia.in/${data.logo.path}`);
        toast.success("Image uploaded and URL saved successfully");
      } else {
        toast.error("Image upload failed");
      }
    } catch (error) {
      // console.error("Error uploading image:", error);
      toast.error("There was an error uploading the image");
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="upload-logo p-6 max-w-lg mx-auto bg-white shadow-lg rounded-lg">
      <form onSubmit={handleImageUpload} className="flex flex-col items-center">
        <label
          htmlFor="logo-upload"
          className="flex flex-col items-center cursor-pointer"
        >
          <div className="p-4 border border-dashed border-gray-300 rounded-lg flex flex-col items-center justify-center">
            <FaCloudUploadAlt className="text-4xl text-gray-500 mb-2" />
            <span className="text-gray-700">Upload Logo Image</span>
            <input
              type="file"
              id="logo-upload"
              accept="image/*"
              onChange={(e) => setImage(e.target.files[0])}
              className="hidden"
            />
          </div>
        </label>
        <button
          type="submit"
          disabled={isUploading}
          className={`mt-4 px-6 py-2 rounded-lg text-white ${
            isUploading ? "bg-gray-400" : "bg-blue-500"
          } hover:bg-blue-600 transition-colors flex items-center justify-center`}
        >
          {isUploading ? (
            <AiOutlineLoading3Quarters className="animate-spin mr-2" />
          ) : (
            "Upload"
          )}
        </button>
      </form>
      {imageUrl && (
        <div className="mt-6 text-center">
          <p className="text-gray-800 font-semibold mb-2">Uploaded Image:</p>
          <img
            src={imageUrl}
            alt="Uploaded logo"
            className="max-w-full h-auto rounded-lg"
          />
        </div>
      )}
      <Toaster />
    </div>
  );
};

export default UploadLogo;
