import React, { useState, useEffect } from 'react';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import toast, { Toaster } from 'react-hot-toast';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [contactDetails, setContactDetails] = useState({
    phone: '',
    email: '',
    address: '',
    googleMapLink: ''
  });
  const [error, setError] = useState('');

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Format data for sending
    const emailData = {
      to: formData.email,
      subject: `Message from ${formData.name}`,
      text: `Name: ${formData.name}\nMobile: ${formData.mobile}\nEmail: ${formData.email}\nMessage: ${formData.message}`,
      html: `
        <p><strong>Name:</strong> ${formData.name}</p>
        <p><strong>Mobile:</strong> ${formData.mobile}</p>
        <p><strong>Email:</strong> ${formData.email}</p>
        <p><strong>Message:</strong> ${formData.message}</p>
      `,
    };

    try {
      const response = await fetch('https://boostapi.biztoindia.in/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
      });

      if (response.ok) {
        toast.success('Email sent successfully!');
        setFormData({ name: '', email: '', mobile: '', message: '' });
      } else {
        // toast.error('Failed to send the email.');
      }
    } catch (error) {
      // toast.error('Error sending email.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Fetch contact details
  useEffect(() => {
    const fetchContactDetails = async () => {
      try {
        const response = await fetch('https://boostapi.biztoindia.in/api/get-contact');
        if (!response.ok) {
          // throw new Error('Error fetching contact details.');
          // toast.error('Error fetching contact details.');
        }
        const data = await response.json();
        // console.log("data",data)
        setContactDetails({
          phone: data.data.phone || '',
          email: data.data.email || '',
          address: data.data.address || '',
          googleMapLink: data.data.googleMapLink || ''
        });
      } catch (err) {
        // setError(err.message);
        // toast.error(err.message);
      }
    };

    fetchContactDetails();
  }, []);

  return (
    <>
      <Helmet>
        <title>Contact Us</title>
      </Helmet>

      <section className="bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto text-center mb-12">
          <h2 className="text-4xl font-extrabold text-red-600">Contact Us</h2>
          <p className="mt-4 text-lg text-gray-600">
            We’d love to hear from you! Whether you’re curious about products or just need assistance, we’re here to help.
          </p>
        </div>

        <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="bg-white p-8 shadow-lg rounded-lg flex flex-col justify-center items-center text-center">
            <FaPhoneAlt className="text-4xl text-red-600 mb-4" />
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">Phone</h3>
            <p className="text-gray-600 mb-4">Call us at any time</p>
            <a href={`tel:${contactDetails.phone}`} className="text-lg text-gray-800 font-semibold">
              {contactDetails.phone}
            </a>
          </div>

          <div className="bg-white p-8 shadow-lg rounded-lg flex flex-col justify-center items-center text-center">
            <FaEnvelope className="text-4xl text-red-600 mb-4" />
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">Email</h3>
            <p className="text-gray-600 mb-4">Reach out via email</p>
            <a href={`mailto:${contactDetails.email}`} className="text-lg text-gray-800 font-semibold">
              {contactDetails.email}
            </a>
</div>
          <div className="bg-white p-8 shadow-lg rounded-lg flex flex-col justify-center items-center text-center">
            <FaMapMarkerAlt className="text-4xl text-red-600 mb-4" />
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">Address</h3>
            <p className="text-gray-600 mb-4">Visit us at our office</p>
            <p className="text-lg text-gray-800 font-semibold">{contactDetails.address}</p>
          </div>

          <div className="bg-white p-8 shadow-lg rounded-lg">
            <h3 className="text-2xl font-semibold text-gray-800 mb-4 text-center">Send Us a Message</h3>
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold mb-2">Full Name</label>
                <input
                  type="text"
                  name="name"
                  className="p-3 rounded-lg border border-gray-300 outline-none focus:ring-2 focus:ring-red-600"
                  placeholder="Your name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold mb-2">Email</label>
                <input
                  type="email"
                  name="email"
                  className="p-3 rounded-lg border border-gray-300 outline-none focus:ring-2 focus:ring-red-600"
                  placeholder="you@example.com"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold mb-2">Mobile</label>
                <input
                  type="text"
                  name="mobile"
                  className="p-3 rounded-lg border border-gray-300 outline-none focus:ring-2 focus:ring-red-600"
                  placeholder="Your mobile number"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold mb-2">Message</label>
                <textarea
                  rows="4"
                  name="message"
                  className="p-3 rounded-lg border border-gray-300 outline-none focus:ring-2 focus:ring-red-600"
                  placeholder="Write your message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="w-full py-3 text-white bg-red-600 rounded-lg hover:bg-red-700 transition-all"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Send Message'}
              </button>
            </form>
          </div>
        </div>

        <div className="mt-12 max-w-7xl mx-auto">
          <h3 className="text-2xl font-semibold text-gray-800 mb-4 text-center">Find Us on the Map</h3>
          <div className="relative overflow-hidden rounded-lg shadow-lg">
            <iframe
              className="w-full h-64 rounded-lg"
              title="Google Maps"
              src={contactDetails.googleMapLink}
              allowFullScreen
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </section>
      <Toaster position="top-right" />
    </>
  );
};

export default ContactUs;
