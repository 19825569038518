import React, { useState } from 'react';
import { Toaster, toast } from 'react-hot-toast';

const UpdateAbout = () => {
  const [aboutUs, setAboutUs] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [emailId, setEmailId] = useState('');
  const [instagramId, setInstagramId] = useState('');
  const [facebookId, setFacebookId] = useState('');
  const [whyChooseUs, setWhyChooseUs] = useState('');
  const [ourMission, setOurMission] = useState('');
  const [companyName, setCompanyName] = useState('');

  const resetForm = () => {
    setAboutUs('');
    setContactNumber('');
    setEmailId('');
    setInstagramId('');
    setFacebookId('');
    setWhyChooseUs('');
    setOurMission('');
    setCompanyName('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://boostapi.biztoindia.in/api/updateabout', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          aboutUs,
          contactNumber,
          emailId,
          instagramId,
          facebookId,
          whyChooseUs,
          ourMission,
          companyName,
        }),
      });

      if (response.ok) {
        toast.success('About Us updated successfully!');
        resetForm();  // Reset the form after successful update
      } else {
        toast.error('Failed to update About Us.');
      }
    } catch (error) {
      toast.error('An error occurred while updating.');
      // console.error(error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 p-4">
      <Toaster position="top-right" reverseOrder={false} />
      <form onSubmit={handleSubmit} className="w-full max-w-lg bg-white shadow-md rounded-lg p-8">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="aboutUs">
            About Us
          </label>
          <textarea
            id="aboutUs"
            value={aboutUs}
            onChange={(e) => setAboutUs(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows="4"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="contactNumber">
            Contact Number
          </label>
          <input
            id="contactNumber"
            type="text"
            value={contactNumber}
            onChange={(e) => setContactNumber(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="emailId">
            Email
          </label>
          <input
            id="emailId"
            type="email"
            value={emailId}
            onChange={(e) => setEmailId(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="instagramId">
            Instagram ID
          </label>
          <input
            id="instagramId"
            type="text"
            value={instagramId}
            onChange={(e) => setInstagramId(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="facebookId">
            Facebook ID
          </label>
          <input
            id="facebookId"
            type="text"
            value={facebookId}
            onChange={(e) => setFacebookId(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="whyChooseUs">
            Why Choose Us
          </label>
          <textarea
            id="whyChooseUs"
            value={whyChooseUs}
            onChange={(e) => setWhyChooseUs(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows="3"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="ourMission">
            Our Mission
          </label>
          <textarea
            id="ourMission"
            value={ourMission}
            onChange={(e) => setOurMission(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows="3"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="companyName">
            Company Name
          </label>
          <input
            id="companyName"
            type="text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Update
        </button>
      </form>
    </div>
  );
};

export default UpdateAbout;
